import logo_light from "../assets/images/logo-light.png";
import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { useLocation } from "react-router";
import { ArrowLeft, CloudOff } from "react-feather";
import { AuthContext } from "../utils/Authcontext";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import GoogleLogin from "react-google-login";

export default function Signup() {
  const {
    register,
    setFilled,
    filled,
    userInfo,
    registerId,
    toastrMsg,
    toastrError,
    setToastrMsg,
    setToastrError,
    verifyOtp,
    resendOtp,
  } = useContext(AuthContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  let navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [otp, setOtp] = useState("");
  const [state, setState] = useState("1");

  const handleIconHover = () => {
    setShowModal(true);
  };

  const handleIconHoverOut = () => {
    setShowModal(false);
  };

  const lookout = () => {
    if (name !== "" && phone !== "" && email !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const checkPasswordStrength = (password) => {
    // Define a regular expression that enforces the specified criteria
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;

    // Test the password against the regular expression
    const isValid = regex.test(password);

    return isValid;
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (name !== "" && phone !== "" && email !== "") {
      register(email, name, phone);
      // if (password1 !== password2) {
      //   toast.error("Passwords do not match");
      //   return;
      // } else if (!checkPasswordStrength(password1)) {
      //   toast.error("Passwod is weak. Set a strong password");
      //   return;
      // } else {
      //   register(firstName, lastName, email, phone, password1, password2);
      // }
    } else {
      toast.error("Fill all the required fields");
    }
  };

  const handleOtpVerify = (e) => {
    e.preventDefault();
    verifyOtp(localStorage.getItem("userId"), otp);
  };

  const handleResendOtp = () => {
    resendOtp(localStorage.getItem("userId"));
  };

  useEffect(() => {
    if (toastrMsg) {
      setToastrError(null);
      toast.success(toastrMsg);
      navigate("/signup/success");
      setToastrMsg(null);
    } else if (toastrError) {
      setToastrMsg(null);
      toast.error(toastrError);
      setToastrError(null);
    }
  }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

  return (
    <>
      <section className="position-relative bg-[url('../../assets/images/bg/bg-4.jpg')] bg-center bg-no-repeat">
        <ToastContainer />
        <div className="absolute inset-0 bg-slate-950/50"></div>

        <div className="container-fluid relative">
          <div className="grid grid-cols-1">
            <div className="lg:col-span-4">
              <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                <div className="text-center mx-auto">
                  <Link to="/index">
                    <img src={logo_light} alt="" />
                  </Link>
                </div>

                <div className="text-center my-auto">
                  <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                    <div className="grid grid-cols-1">
                      <h5 className="mb-8 text-xl dark:text-white font-medium">
                        Signup
                      </h5>
                      <div className="text-center">
                        {/* <GoogleLogin
                          clientId="248396148558-cl9f2vtpbjvlj1038584nn0r6u7mhivv.apps.googleusercontent.com"
                          // onSuccess={handleOnSuccess}
                          // onFailure={handleOnFailure}
                          isLoggedIn={true}
                        /> */}
                      </div>

                      <form className="text-start">
                        <div className="grid grid-cols-1">
                          <div className="mb-2">
                            <label
                              className="form-label font-medium"
                              htmlFor="firstName"
                            >
                              Name:
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                              placeholder="Name"
                              value={name}
                              onChange={(event) => {
                                setName(event.target.value);
                                lookout();
                              }}
                            />
                          </div>
                          {/* <div className="mb-2">
                            <label
                              className="form-label font-medium"
                              htmlFor="firstName"
                            >
                              First Name:
                            </label>
                            <input
                              id="firstName"
                              type="text"
                              className="form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                              placeholder="First Name"
                              value={firstName}
                              onChange={(event) => {
                                setFirstName(event.target.value);
                                lookout();
                              }}
                            />
                          </div>

                          <div className="mb-2">
                            <label
                              className="form-label font-medium"
                              htmlFor="lastName"
                            >
                              Last Name:
                            </label>
                            <input
                              id="lastName"
                              type="text"
                              className="form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                              placeholder="Last Name"
                              value={lastName}
                              onChange={(event) => {
                                setLastName(event.target.value);
                                lookout();
                              }}
                            />
                          </div> */}

                          <div className="mb-2">
                            <label
                              className="form-label font-medium"
                              htmlFor="email"
                            >
                              Email Address:
                            </label>
                            <input
                              id="email"
                              type="email"
                              className="form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                              placeholder="Email"
                              value={email}
                              onChange={(event) => {
                                setEmail(event.target.value);
                                lookout();
                              }}
                            />
                          </div>

                          <div className="mb-2">
                            <label
                              className="form-label font-medium"
                              htmlFor="phone"
                            >
                              Phone:
                            </label>

                            <PhoneInput
                              id="phone"
                              placeholder="Phone Number"
                              value={phone}
                              onChange={(event) => {
                                setPhone(event);
                                lookout();
                              }}
                              className="form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                            />
                          </div>

                          {/* <div className="mb-2">
                            <label
                              className="form-label font-medium"
                              htmlFor="password1"
                            >
                              Password:<span className="required">*</span>
                              <span
                                className="info-icon"
                                onClick={handleIconHover}
                                // onMouseLeave={handleIconHoverOut}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                            </label>
                            <input
                              id="password1"
                              type="password"
                              className="form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                              placeholder="Password:"
                              value={password1}
                              onChange={(event) => {
                                setPassword1(event.target.value);
                                lookout();
                              }}
                            />

                            <Modal
                              isOpen={showModal}
                              onRequestClose={handleIconHoverOut}
                              contentLabel="Password Rules"
                              style={{
                                position: "relative",
                                content: {
                                  maxWidth: "16rem", // Adjust this value to control the width
                                  maxHeight: "25rem",
                                  margin: "auto",
                                  zIndex: 1000, // Set a higher z-index to ensure it appears above other elements
                                },
                              }}
                            >
                              <button
                                className="bg-gold-600 rounded-circle btn-lg"
                                style={{
                                  position: "absolute",
                                  right: "-4px",
                                  top: "-2px",
                                  color: "white",
                                  border: "none",
                                  padding: "5px 10px",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                onClick={handleIconHoverOut}
                              >
                                <span style={{ fontWeight: "bold" }}>X</span>
                              </button>
                              <h2
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                }}
                              >
                                Password Creation Rules
                              </h2>
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "20px",
                                  margin: "0",
                                }}
                              >
                                <li
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  At least 8 characters long
                                </li>
                                <li
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Must contain at least one uppercase letter
                                </li>
                                <li
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Must contain at least one lowercase letter
                                </li>
                                <li
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Must contain at least one digit
                                </li>
                                <li
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Must contain at least one special character
                                </li>
                              </ul>
                            </Modal>
                          </div>

                          <div className="mb-2">
                            <label
                              className="form-label font-medium"
                              htmlFor="password2"
                            >
                              Retype Password:
                              <span className="required">*</span>
                            </label>
                            <input
                              id="password2"
                              type="password"
                              className="form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                              placeholder="Retype Password:"
                              value={password2}
                              onChange={(event) => {
                                setPassword2(event.target.value);
                                lookout();
                              }}
                            />
                          </div> */}

                          <div className="mb-4 mt-2">
                            <Link
                              disabled={disabled}
                              className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md w-full"
                              onClick={(e) => {
                                handleRegister(e);
                              }}
                            >
                              Register
                            </Link>
                          </div>

                          <div className="text-center">
                            <span className="text-slate-400 me-2">
                              Already have an account ?{" "}
                            </span>{" "}
                            <Link
                              to="/login"
                              className="text-slate-950 dark:text-white font-bold"
                            >
                              {" "}
                              Sign in
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="fixed bottom-3 end-3">
        <Link
          to="/"
          className="back-button h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white"
        >
          <ArrowLeft className="h-4 w-4"></ArrowLeft>
        </Link>
      </div>
    </>
  );
}
