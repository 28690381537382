import React from "react";
import OurServices from "../component/our-services";
import MeetOurs from "../component/meet-ours";
import About from "../component/about";
import { Link } from "react-router-dom";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import about from "../assets/images/about.jpg";

export default function AboutUs() {
  return (
    <>
      <div className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
        <Navbar />
        <section
          className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"
          id="home"
        >
          <div className="absolute inset-0 bg-slate-950/80"></div>

          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
              <h3 className="font-medium leading-normal text-3xl mt-10 text-white">
                Company Story
              </h3>
            </div>
          </div>
        </section>
        <section className="relative md:py-24 py-16" id="about">
          <section className="relative md:py-24 py-16" id="about">
            <div className="container mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                <div className="lg:col-span-5">
                  <div className="relative">
                    <img
                      src={about}
                      className="rounded-lg shadow-lg relative"
                      alt=""
                    />
                    <div className="absolute bottom-2/4 translate-y-2/4 end-0 start-0 text-center">
                      <Link
                        to="#!"
                        data-type="youtube"
                        data-id="yba7hPeTSjk"
                        className="lightbox h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-950 text-gold-600"
                      >
                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-7">
                  <div className="lg:ms-7">
                    <h3 className="mb-4 md:text-2xl text-xl font-medium">
                      About Us: Collablink
                    </h3>

                    <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                      At Collablink, we're redifining real estate investment.
                      Our innovative approach allows you to invest in premium
                      properties from top builders like Sobha, Prestige,
                      Brigade, and more, even with limited funds.
                    </p>

                    <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto mt-2">
                      Here's how it works: We secure apartment unit during the
                      launch phase, and each unit becomes its own investment
                      project. These projects are divided into shares, making
                      high-value properties accessible to everyone. As
                      construction progresses from shareholders, simplifying the
                      investment process.
                    </p>

                    <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto mt-2">
                      When the project is completed, we sell the unit and
                      distribute the appreciated value among our shareholders,
                      deducting only a nominal 2% commission.
                    </p>

                    <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto mt-2">
                      Our upcoming website will provide a user-friendly portal
                      where you can monitor your investments, explore ongoing
                      and new projects, and even trade your shares with fellow
                      investors. We're here to make smart real estate investment
                      a reality for you.
                    </p>

                    <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto mt-2">
                      Join us on this exciting journey to discover the world of
                      hassle-free, profitable real estate investment. Welcome to
                      Collablink!"
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <OurServices />
          <MeetOurs />
          <div className="container lg:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
              <h3 className="mb-6 md:text-2xl text-xl font-medium">
                Have Question ? Get in touch!
              </h3>

              <p className="text-slate-400 max-w-xl mx-auto">
                This is just a simple text made for this unique and awesome
                template, you can replace it with any text.
              </p>

              <div className="mt-6">
                <Link
                  to="/contactus"
                  className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md"
                >
                  <i className="uil uil-phone align-middle me-2"></i> Contact us
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
