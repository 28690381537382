import React, { useState, useContext, useEffect } from "react";
// import NavbarSmallLight from "../component/navbar-small-light";
import Navbar from "../component/navbar";
import image1 from "../assets/images/collab_white.png";
import About from "../component/about";
import OurServices from "../component/our-services";
import ComfortablePricing from "../component/comfortable-pricing";
import ClientSreview from "../component/clientsreview";
import BlogsNews from "../component/blogs-news";
import GetInTouch from "../component/get-in-touch";
import Footer from "../component/footer";
import cta from "../assets/images/cta.jpg";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.css";
import { ToastContainer, toast } from "react-toastify";
import {
  Facebook,
  GitHub,
  Gitlab,
  Instagram,
  Linkedin,
  Mail,
  MessageCircle,
  Twitter,
  User,
  Youtube,
} from "react-feather";
import { AuthContext } from "../utils/Authcontext";
import { BASE_URL } from "../utils/config";

const UserProfile = () => {
  const { myDetails, getUserDetails, updateUserDetails, toastrMsg, toastrError, setToastrMsg, setToastrError, changePassword} =
    useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pan, setPan] = useState("");
  const [panFile, setPanFile] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [aadharFile, setAadharFile] = useState("");
  const [profile, setProfile] = useState("");
  const [passDisabled, setPassDisabled] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    profilePic: "",
    pan: "",
    panFile: null,
    aadhar: "",
    aadharFile: null,
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  })

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (myDetails) {
      setFormData({
        name: `${myDetails.first_name} ${myDetails.last_name}`,
        phone: myDetails.phone ?? "",
        email: myDetails.email ?? "",
        // profilePic: "",
        pan: myDetails.pan ?? "",
        panFile: "",
        aadhar: myDetails.aadhar ?? "",
        aadharFile: "",
      });
      setName(`${myDetails.first_name} ${myDetails.last_name}`);
      setEmail(myDetails.email ?? "");
      setPhone(myDetails.phone ?? "");
      setPan(myDetails.pan ?? "");
      setAadhar(myDetails.aadhar ?? "");
      // setProfile(myDetails.profile_pic ?? "");
      setAadharFile(myDetails.aadhar_file ?? "");
      setPanFile(myDetails.pan_file ?? "");
      
    }
  }, [myDetails]);

  useEffect(() => {
    if (toastrMsg) {
      setToastrError(null);
      toast.success(toastrMsg);
      setToastrMsg(null);
    } else if (toastrError) {
      setToastrMsg(null);
      toast.error(toastrError);
      setToastrError(null);
    }
  }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

  const handleChange = (name, value) => {
    // const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handlePassChange = (key, value) => {
    setPasswordData((prevData) => ({ ...prevData, [key]: value.trim()}))
    if (passwordData.newPassword !== "" && passwordData.confirmPassword !== "" && passwordData.currentPassword !== "") {
      setPassDisabled(false)
    }
    else {
      setPassDisabled(true)
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      identificationDocument: file,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle form submission, e.g., API call
    // console.log("Form submitted:", formData);
    if (name !== "" && email !== "" && phone !== null) {
      updateUserDetails(formData);
    }
    else {
      toast.error("Name, email and phone fields cannot be empty.")
    }
  };

  const handlePassSubmit = (e) => {
    e.preventDefault();
    changePassword(passwordData)
  }

  return (
    <>
      <ToastContainer />
      <Navbar />
      <section
        className="h-full w-full table relative  bg-bottom bg-no-repeat"
        id="home"
      >
        <div className="absolute inset-0 bg-slate-950/80"></div>

        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-3 font-medium leading-normal text-5xl mt-8 text-white">
              My Profile
            </h3>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container">
          
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-8 md:col-span-6">
              
              <div className="p-6 rounded-md shadow dark:shadow-gray-800">
                <div>
                <h5 className="text-lg dark:text-white mb-2 font-medium">Personal Information</h5>
                <div className="mt-6">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-600"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={(e) => {
                          setName(e.target.value);
                          handleChange("name", e.target.value);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-600"
                      >
                        Phone
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          handleChange("phone", e.target.value);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-600"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        className="mt-1 p-2 w-full border rounded-md"
                        disabled={true}
                      />
                    </div>

                    {/* <div className="mb-4">
                      <label
                        htmlFor="profilePic"
                        className="block text-sm font-medium text-gray-600"
                      >
                        Profile Pic{" "}
                        {myDetails?.profile_pic && (
                          <span className="ms-3">
                            <a
                              href={`${BASE_URL}${myDetails.profile_pic}`}
                              className="text-info"
                              target="_blank"
                            >
                              View
                            </a>
                          </span>
                        )}
                      </label>
                      <input
                        type="file"
                        id="profilePic"
                        name="profilePic"
                        onChange={(e) => {
                          handleChange("profilePic", e.target.files[0]);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div> */}

                    <div className="mb-4">
                      <label
                        htmlFor="pan"
                        className="block text-sm font-medium text-gray-600"
                      >
                        PAN
                      </label>
                      <input
                        type="text"
                        id="pan"
                        name="pan"
                        value={formData.pan}
                        onChange={(e) => {
                          setPan(e.target.value);
                          handleChange("pan", e.target.value);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="panFile"
                        className="block text-sm font-medium text-gray-600"
                      >
                        Upload Copy of PAN{" "}
                        {myDetails?.pan_file && (
                          <span className="ms-3">
                            <a
                              href={`${BASE_URL}${myDetails.pan_file}`}
                              className="text-info"
                              target="_blank"
                            >
                              View
                            </a>
                          </span>
                        )}
                      </label>
                      <input
                        type="file"
                        id="panFile"
                        name="panFile"
                        onChange={(e) => {
                          handleChange("panFile", e.target.files[0]);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="aadhar"
                        className="block text-sm font-medium text-gray-600"
                      >
                        Aadhar Number
                      </label>
                      <input
                        type="text"
                        id="aadhar"
                        name="aadhar"
                        value={formData.aadhar}
                        onChange={(e) => {
                          setAadhar(e.target.value);
                          handleChange("aadhar", e.target.value);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="aadharFile"
                        className="block text-sm font-medium text-gray-600"
                      >
                        Upload Copy of Aadhar{" "}
                        {myDetails?.aadhar_file && (
                          <span className="ms-3">
                            <a
                              href={`${BASE_URL}${myDetails.aadhar_file}`}
                              className="text-info"
                              target="_blank"
                            >
                              View
                            </a>
                          </span>
                        )}
                      </label>
                      <input
                        type="file"
                        id="aadharFile"
                        name="aadharFile"
                        onChange={(e) => {
                          handleChange("aadharFile", e.target.files[0]);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>

                    <div
                      className="my-4 flex flex-row"
                      style={{
                        display: "flex",
                        flex: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        onClick={(e) => {}}
                        className="py-2 px-5 font-normal  border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md flex justify-end"
                      >
                        Edit
                      </button>
                    </div>
                  </form>
                </div>
                </div>

                <div>

                <h5 className="text-lg dark:text-white mt-10 mb-2 font-medium">Change Password</h5>
                
                <div className="mt-6">
                  <form onSubmit={handlePassSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="current-password"
                        className="block text-sm font-medium text-gray-600"
                      >
                        Current Password
                      </label>
                      <input
                        type="password"
                        id="current-password"
                        name="current-password"
                        value={passwordData?.currentPassword}
                        onChange={(e) => {
                          setName(e.target.value);
                          handlePassChange("currentPassword", e.target.value);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="new-password"
                        className="block text-sm font-medium text-gray-600"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        id="new-password"
                        name="new-password"
                        value={passwordData?.newPassword}
                        onChange={(e) => {
                          setName(e.target.value);
                          handlePassChange("newPassword", e.target.value);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="confirm-password"
                        className="block text-sm font-medium text-gray-600"
                      >
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        id="confirm-password"
                        name="confirm-password"
                        value={passwordData?.confirmPassword}
                        onChange={(e) => {
                          setName(e.target.value);
                          handlePassChange("confirmPassword", e.target.value);
                        }}
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>

                    

                    <div
                      className="my-4 flex flex-row"
                      style={{
                        display: "flex",
                        flex: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        onClick={(e) => {
                          
                        }}
                        className="py-2 px-5 font-normal  border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md flex justify-end"
                        disabled={passDisabled}
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>

                </div>
               

              </div>
           
            </div>

            <div className="lg:col-span-4 md:col-span-6">
              <div className="sticky top-20">
                <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">
                  {JSON.parse(localStorage.getItem("cluserInfo"))?.name}
                </h5>
                <div className="mt-8">
                  {/* <div className="h-24 w-24 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white">
                    <i className="uil uil-user" size="lg"></i>
                  </div> */}
                  <img
                    className="h-24 w-24 mx-auto"
                    alt="..."
                    src={`https://ui-avatars.com/api/?name=${
                      JSON.parse(localStorage.getItem("cluserInfo"))?.name
                    }&background=A88B5C&bold=true&rounded=true`}
                  />
                  {/* <img src=`https://ui-avatars.com/api/?name=${JSON.parse(localStorage.getItem("cluserInfo")).name}&background=BBDEFB&bold=true&rounded=true`></img> */}
                  {/* <img
                    src={`${BASE_URL}${myDetails?.profile_pic}`}
                    className="h-24 w-24 mx-auto rounded-full shadow mb-4"
                    alt=""
                  /> */}

                  <div className="mt-3" style={{ width: "100%" }}>
                    <div
                      className="h5 mb-3"
                      style={{ display: "inline-block", width: "25%" }}
                    >
                      Email
                    </div>
                    <div
                      className=" text-gold-600 whitespace-normal"
                      style={{
                        display: "inline-block",
                        width: "75%",
                        wordBreak: "break-all",
                        verticalAlign: "top",
                      }}
                    >
                      {JSON.parse(localStorage.getItem("cluserInfo"))?.email}
                    </div>
                    <div
                      className="h5 mb-3"
                      style={{ display: "inline-block", width: "25%" }}
                    >
                      Phone
                    </div>
                    <div
                      className="text-gold-600"
                      style={{ display: "inline-block", width: "75%" }}
                    >
                      {myDetails?.phone}
                    </div>
                  </div>
                </div>

                <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">
                  {myDetails?.pan_file ? "PAN Uploaded" : "PAN Not Uploaded"}
                </h5>
                <ul className="list-none text-center mt-8">
                  {/* Social links go here */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default UserProfile;
