import logo_light from "../assets/images/logo-light.png";
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { AuthContext } from "../utils/Authcontext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function OtpVerify() {
  const navigate = useNavigate();
  const {
    filled,
    resendOtp,
    verifyOtp,
    userInfo,
    toastrMsg,
    toastrError,
    setToastrMsg,
    setToastrError,
  } = useContext(AuthContext);
  const [otp, setOtp] = useState("");

  const handleOtpVerify = (e) => {
    e.preventDefault();
    verifyOtp(localStorage.getItem("userId"), otp);
  };

  const handleResendOtp = () => {
    resendOtp(localStorage.getItem("userId"));
  };

  useEffect(() => {
    if (toastrMsg) {
      setToastrError(null);
      toast.success(toastrMsg);
      setToastrMsg(null);
      navigate("/");
    } else if (toastrError) {
      setToastrMsg(null);
      toast.error(toastrError);
      setToastrError(null);
    }
  }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

  return (
    <>
      <div className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
        <ToastContainer />
        <section className="position-relative bg-[url('../../assets/images/bg/bg-4.jpg')] bg-center bg-no-repeat">
          <div className="absolute inset-0 bg-slate-950/50"></div>
          <div className="container-fluid relative">
            <div className="grid grid-cols-1">
              <div className="lg:col-span-4">
                <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                  <div className="text-center mx-auto">
                    <Link to="/index">
                      <img src={logo_light} alt="" />
                    </Link>
                  </div>
                  <div className="text-center my-auto">
                    <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                      <div className="grid grid-cols-1">
                        <h5 className="mb-8 text-xl dark:text-white font-medium">
                          Regsitration Successful!!!
                        </h5>
                        <p className="text-slate-400 mb-6 text-center">
                          Thank you for registering with Collab Link. We have
                          sent a temporary password to your registered mail ID.
                          <br></br>
                          Click{" "}
                          <Link to="/login" className="text-gold-600">
                            here
                          </Link>{" "}
                          to go to Login Page.
                        </p>
                        {/* <form className="text-start">
                          <div className="grid grid-cols-1">
                            <div className="mb-4">
                              <input
                                id="otp"
                                type="number"
                                value={otp}
                                className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                                onChange={(event) => setOtp(event.target.value)}
                              />
                            </div>

                            <div className="mb-4">
                              <Link
                                onClick={(e) => {
                                  handleOtpVerify(e);
                                }}
                                className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md w-full"
                              >
                                Send
                              </Link>
                            </div>

                            <div className="text-center">
                              <Link
                                onClick={() => {
                                  handleResendOtp();
                                }}
                                to=""
                                className="text-slate-950 dark:text-white font-bold"
                              >
                                Resend OTP
                              </Link>
                            </div>
                          </div>
                        </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="fixed bottom-3 end-3">
          <Link
            to=""
            className="back-button h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white"
          >
            <ArrowLeft className="h-4 w-4"></ArrowLeft>
          </Link>
        </div>
      </div>
    </>
  );
}
