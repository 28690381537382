import React, { useState, useEffect, useContext } from "react";
import image1 from "../assets/images/blog/brigade_valencia.png";
import image2 from "../assets/images/blog/brigade_cornerstone_utopia.png";
import image3 from "../assets/images/blog/brigade_el_dorado.png";
import image4 from "../assets/images/blog/kallat_private_pool.png";
import { Link } from "react-router-dom";
// import { Carousel } from "react-responsive-carousel";
import Carousel from "react-multi-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import "react-multi-carousel/lib/styles.css";
import { AuthContext } from "../utils/Authcontext";
import { BASE_URL } from "../utils/config";

export default function BlogsNews() {
  const { projects, projectData } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const [tabs, setTabs] = useState(null);

  useEffect(() => {
    projects();
  }, []);

  useEffect(() => {
    if (projectData) {
      setTabs([
        {
          id: 1,
          label: "Ongoing Projects",
          data: [
            ...(projectData && projectData.filter((x) => x.status === "1")),
          ],
        },
        {
          id: 2,
          label: "Upcoming Projects",
          data: [
            ...(projectData && projectData.filter((x) => x.status === "2")),
          ],
        },
      ]);
    }
    // console.log(tabs);
  }, [projectData]);

  const BlogsNewsdata = [
    {
      image: image1,
      title: "2 BHK in Brigade Valencia",
      // detail:
      //   "The phrasal sequence of the is now so that many campaign and benefit",
      // more: "Read More",
    },
    {
      image: image2,
      title: "3 BHK Compact in Brigade El Dorado",
      // detail:
      //   "The phrasal sequence of the is now so that many campaign and benefit",
      // more: "Read More",
    },
    {
      image: image3,
      title: "Studio unit in Brigade Cornerstone Utopia",
      // detail:
      //   "The phrasal sequence of the is now so that many campaign and benefit",
      // more: "Read More",
    },
    {
      image: image4,
      title: "Private Pool Villa in Wayanad",
      // detail:
      //   "The phrasal sequence of the is now so that many campaign and benefit",
      // more: "Read More",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <section className="container lg:py-24 py-16" id="blog">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 md:text-2xl text-xl font-medium">Projects</h3>

          {/* <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
            Launch your campaign and benefit from our expertise on designing and
            managing conversion centered Tailwind CSS html page.
          </p> */}
          <div className="flex w-full justify-between">
            {tabs &&
              tabs.map((tab) => (
                <button
                  key={tab.id}
                  className={`${
                    selectedTab === tab.id
                      ? "bg-gold-600 text-white"
                      : "bg-white text-gold-600"
                  } px-4 py-2 rounded-lg flex-1`}
                  onClick={() => setSelectedTab(tab.id)}
                >
                  {tab.label}
                </button>
              ))}
          </div>
        </div>
        <div>
          {tabs && (
            <Carousel
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              responsive={responsive}
              swipeable={true}
              draggable={true}
              ssr={false}
              // infinite={true}
              autoPlay={false}
              showDots={true}
              // centerMode={isLessThanThreeImages ? true : false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="absolute left-0.01rem top-50% z-2 bg-gray-800 text-white hover:bg-gray-600 rounded-full p-2"
                  >
                    &lt;
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="absolute right-0.01rem top-50% z-2 bg-gray-800 text-white hover:bg-gray-600 rounded-full p-2"
                  >
                    &gt;
                  </button>
                )
              }
            >
              {tabs &&
                tabs
                  .find((tab) => tab.id === selectedTab)
                  .data.map((item, index) => (
                    <div
                      className="blog relative rounded-md shadow shadow-gold-600/50 dark:shadow-gold-600 overflow-hidden"
                      key={index}
                    >
                      <img
                        src={`${BASE_URL}${item.profile_image}`}
                        // src="http:127.0.0.1:8000/media/projects/brigade_valencia.png"
                        alt=""
                        style={{ height: "200px", width: "350px" }}
                      />

                      <div className="content p-6">
                        <Link
                          to={`/blog-detail/${item.id}`}
                          className="text-lg hover:text-gold-600 dark:text-white dark:hover:text-gold-600 transition duration-500 ease-in-out font-medium h5"
                        >
                          {item.project_name}
                        </Link>
                      </div>
                    </div>
                  ))}
            </Carousel>
          )}
        </div>
      </section>
    </>
  );
}
