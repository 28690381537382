import logo_dark from "../assets/images/logo-dark.png";
import logo_light from "../assets/images/logo-light.png";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as Link1 } from "react-scroll";
import { AuthContext } from "../utils/Authcontext";
import { useMediaQuery } from "react-responsive";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";

export default function NavbarSmallLight() {
  const [isOpen, setMenu] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  window.addEventListener("scroll", windowScroll);

  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add("is-sticky");
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove("is-sticky");
      }
    }

    const mybutton = document.getElementById("back-to-top");
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.classList.add("flex");
        mybutton.classList.remove("hidden");
      } else {
        mybutton.classList.add("hidden");
        mybutton.classList.remove("flex");
      }
    }
  }

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const toggleMenu = () => {
    setMenu(!isOpen);
  };

  const handleLogout = () => {
    setMenu(!isOpen);
    localStorage.removeItem("cluserInfo");
    localStorage.removeItem("userId");
    navigate("/");
  };

  const handleClick = () => {
    if (localStorage.getItem("cluserInfo")) {
      localStorage.removeItem("cluserInfo");
      localStorage.removeItem("userId");
    }
  };
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  return (
    <>
      <nav className="navbar" id="navbar">
        <div className="container flex flex-wrap items-center justify-end">
          <a className="navbar-brand md:me-8" href="/index">
            <span className="inline-block dark:hidden">
              <img
                src={logo_dark}
                className="l-dark"
                alt=""
                // style={{ width: "6rem" }}
              />
              <img
                src={logo_light}
                className="l-light"
                alt=""
                // style={{ width: "6rem" }}
              />
            </span>
            <img
              src={logo_light}
              className="hidden dark:inline-block"
              alt=""
              // style={{ width: "6rem" }}
            />
          </a>

          <div className="nav-icons flex items-center lg_992:order-2 ms-auto lg:ms-4">
            <ul className="list-none menu-social mb-0">
              {(localStorage.getItem("cluserInfo") && isDesktop && (
                <>
                  <li className="inline">
                    <button
                      type="button"
                      className="inline-flex h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white"
                      onClick={() => handleDropdown()}
                    >
                      <img
                        className="h-24 w-24 mx-auto"
                        alt="..."
                        src={`https://ui-avatars.com/api/?name=${
                          JSON.parse(localStorage.getItem("cluserInfo"))?.name
                        }&background=A88B5C&bold=true&rounded=true`}
                      />
                    </button>
                    {/* <UncontrolledDropdown nav>
                      <DropdownToggle className="pr-0" nav></DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem className="noti-title" header tag="div">
                          <h6 className="text-overflow m-0">Welcome!</h6>
                        </DropdownItem>
                        <DropdownItem to="/admin/user-profile" tag={Link}>
                          <i className="ni ni-single-02" />
                          <span>My profile</span>
                        </DropdownItem>
                        <DropdownItem to="/admin/user-profile" tag={Link}>
                          <i className="ni ni-settings-gear-65" />
                          <span>Settings</span>
                        </DropdownItem>

                        <DropdownItem divider />
                        <DropdownItem onClick={() => handleLogout()}>
                          <i className="ni ni-user-run" />
                          <span>Logout</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                  </li>
                  <div
                    className={`${
                      dropdown
                        ? "absolute mt-2  bg-white rounded-lg shadow-lg left-0 block"
                        : "absolute mt-2  bg-white rounded-lg shadow-lg left-0 hidden"
                    }`}
                  >
                    <ul className="p-2">
                      <li className="cursor-pointer px-2 py-2 text-sm">
                        <Link to="/profile">View Profile</Link>
                      </li>
                      <li
                        className="cursor-pointer px-2 py-2 text-sm"
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </li>
                    </ul>
                  </div>
                </>
              )) ||
                (!localStorage.getItem("cluserInfo") && (
                  <li className="inline">
                    <Link
                      to={localStorage.getItem("cluserInfo") ? "/" : "/login"}
                      className="h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white"
                      // onClick={handleClick()}
                    >
                      <i className="uil uil-user"></i>
                    </Link>
                  </li>
                ))}
            </ul>

            {/* <UncontrolledDropdown>
              <DropdownToggle className="pr-0">
                <Media className="align-items-center">
                  <span className="h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white">
                    <i className="uil uil-user"></i>
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}

            <button
              data-collapse="menu-collapse"
              type="button"
              onClick={toggleMenu}
              className="collapse-btn inline-flex items-center ms-3 text-slate-950 dark:text-white lg_992:hidden"
              aria-controls="menu-collapse"
              aria-expanded="false"
            >
              <span className="sr-only">Navigation Menu</span>
              <i className="mdi mdi-menu text-[24px]"></i>
            </button>
          </div>
          <div
            className={`${
              isOpen === true
                ? "navigation lg_992:order-1 lg_992:flex hidden ms-auto"
                : "navigation lg_992:order-1 lg_992:flex block ms-auto"
            }`}
            id="menu-collapse"
          >
            <ul className="navbar-nav nav-light" id="navbar-navlist">
              <li className={`nav-item`}>
                <Link1
                  to="home"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="nav-link"
                >
                  Home
                </Link1>
              </li>
              {/* <li className={`nav-item`}>
                <Link1
                  to="features"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="nav-link"
                >
                  Services
                </Link1>
              </li> */}
              {/* <li className={`nav-item`}>
                <Link1
                  to="pricing"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="nav-link"
                >
                  Pricing
                </Link1>
              </li> */}
              {/* <li className={`nav-item`}>
                <Link1
                  to="testi"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="nav-link"
                >
                  Review
                </Link1>
              </li> */}
              <li className={`nav-item`}>
                <Link1
                  to="blog"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="nav-link"
                >
                  Projects
                </Link1>
              </li>
              <li className={`nav-item`}>
                <Link1
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="nav-link"
                >
                  About Us
                </Link1>
              </li>
              <li className={`nav-item`}>
                <Link1
                  to="contact"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="nav-link"
                >
                  Contact us
                </Link1>
              </li>

              {localStorage.getItem("cluserInfo") && !isDesktop && (
                <>
                  <li className={`nav-item`}>
                    <Link1
                      // to="contact"
                      activeClass="active"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      View Profile
                    </Link1>
                  </li>
                  <li className={`nav-item`}>
                    <Link1
                      to="contact"
                      activeClass="active"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      Logout
                    </Link1>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
