import image from "../assets/images/blog/9.jpg";
import image1 from "../assets/images/client/05.jpg";
import React, { useContext, useState, useEffect } from "react";
import Navbar from "../component/navbar";
import RelatedBlogs from "../component/related-blogs";
import Footer from "../component/footer";
import { Link, useParams } from "react-router-dom";
import {
  Facebook,
  GitHub,
  Gitlab,
  Instagram,
  Linkedin,
  Mail,
  MessageCircle,
  Twitter,
  User,
  Youtube,
} from "react-feather";

import { AuthContext } from "../utils/Authcontext";
import { BASE_URL } from "../utils/config";

export default function BlogDetail() {
  const { getProjectDetails, projectDetails } = useContext(AuthContext);
  const id = useParams();
  // console.log(id);
  useEffect(() => {
    getProjectDetails(id.id);
  }, []);
  return (
    projectDetails && (
      <>
        <Navbar />
        <section
          className="h-full w-full table relative  bg-bottom bg-no-repeat py-28"
          id="home"
        >
          <div className="absolute inset-0 bg-slate-950/80"></div>

          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center mt-10">
              <h3 className="mb-3 font-medium leading-normal text-5xl mt-8 text-white">
                {projectDetails.project_name}
              </h3>

              <ul className="list-none mt-6">
                {/* <li className="inline-block text-white/50 mx-5">
                  {" "}
                  <span className="text-white">Status :</span>{" "}
                  <span className="text-white">
                    {(projectDetails.status === "1" && "Ongoing Project") ||
                      (projectDetails.status === "2" && "Upcoming Project")}
                  </span>
                </li> */}
                {/* <li className="inline-block text-white/50 mx-5">
                  {" "}
                  <span className="text-white ">Total Cost :</span>{" "}
                  <span className="text-white">
                    ₹{projectDetails.total_cost}
                  </span>
                </li>
                <li className="inline-block text-white/50 mx-5">
                  {" "}
                  <span className="text-white ">Shares :</span>{" "}
                  <span className="text-white">{projectDetails.shares}</span>
                </li> */}
              </ul>
            </div>
          </div>
        </section>
        <section className="relative md:py-24 py-16">
          <div className="container">
            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
              <div className="lg:col-span-8 md:col-span-6">
                <div className="p-6 rounded-md shadow dark:shadow-gray-800">
                  <img
                    src={`${BASE_URL}${projectDetails.profile_image}`}
                    className="rounded-md"
                    alt=""
                  />

                  <div className="mt-6">
                    {/* <p className="text-slate-400 italic border-x-4 border-gold-600 rounded-ss-xl rounded-ee-xl mt-3 p-3"> */}
                    {/* </p> */}
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Project name
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.project_name}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Builder
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.builder}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Type
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.type}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h5 transition d6ration-500 ease-in-out text-gold-600 text-gold-600">
                            Area
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.area}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Location
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.location}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Landmark
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.landmark}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Purchase Date
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.purchase_date}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            RERA Completion date
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.rera_completion_date}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Total Cost
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.total_cost}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Total Shares
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.shares}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Current Market Value
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.current_market_value}
                          </p>
                        </div>
                      </div>
                      <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="mt-6">
                          <div className="text-lg h6 transition duration-500 ease-in-out text-gold-600">
                            Gain
                          </div>
                          <p className="text-dark font-bold transition duration-500 ease-in-out mt-3">
                            {projectDetails.gain}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-6 rounded-md shadow dark:shadow-gray-800 mt-8">
                  <h5 className="text-lg font-semibold">Leave A Comment:</h5>

                  <form className="mt-8">
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <div className="text-start">
                          <label htmlFor="name" className="font-semibold">
                            Your Name:
                          </label>
                          <div className="form-icon relative mt-2">
                            <User className="w-4 h-4 absolute top-3 start-4"></User>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-input w-full py-2 px-3 ps-11 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0"
                              placeholder="Name :"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <div className="text-start">
                          <label htmlFor="email" className="font-semibold">
                            Your Email:
                          </label>
                          <div className="form-icon relative mt-2">
                            <Mail className="w-4 h-4 absolute top-3 start-4"></Mail>
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-input w-full py-2 px-3 ps-11 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0"
                              placeholder="Email :"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <div className="text-start">
                          <label htmlFor="comments" className="font-semibold">
                            Your Comment:
                          </label>
                          <div className="form-icon relative mt-2">
                            <MessageCircle className="w-4 h-4 absolute top-3 start-4"></MessageCircle>
                            <textarea
                              name="comments"
                              id="comments"
                              className="form-input w-full py-2 px-3 ps-11 h-28 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0"
                              placeholder="Message :"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md w-full"
                    >
                      Send Message
                    </button>
                  </form>
                </div>
              </div>

              <div className="lg:col-span-4 md:col-span-6">
                <div className="sticky top-20">
                  <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">
                    Author
                  </h5>
                  <div className="text-center mt-8">
                    <img
                      src={image1}
                      className="h-24 w-24 mx-auto rounded-full shadow mb-4"
                      alt=""
                    />

                    <Link
                      to=""
                      className="text-lg font-medium hover:text-gold-600 transition-all duration-500 ease-in-out h5"
                    >
                      Cristina Romsey
                    </Link>
                    <p className="text-slate-400">Content Writer</p>
                  </div>

                  <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">
                    Social sites
                  </h5>
                  <ul className="list-none text-center mt-8">
                    <li className="inline">
                      <Link
                        to=""
                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-gold-600 hover:text-white hover:bg-gold-600"
                      >
                        <Facebook className="h-4 w-4"></Facebook>
                      </Link>
                    </li>
                    <li className="inline ms-1">
                      <Link
                        to=""
                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-gold-600 hover:text-white hover:bg-gold-600"
                      >
                        <Instagram className="h-4 w-4"></Instagram>
                      </Link>
                    </li>
                    <li className="inline ms-1">
                      <Link
                        to=""
                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-gold-600 hover:text-white hover:bg-gold-600"
                      >
                        <Twitter className="h-4 w-4"></Twitter>
                      </Link>
                    </li>
                    <li className="inline ms-1">
                      <Link
                        to=""
                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-gold-600 hover:text-white hover:bg-gold-600"
                      >
                        <Linkedin className="h-4 w-4"></Linkedin>
                      </Link>
                    </li>
                    <li className="inline ms-1">
                      <Link
                        to=""
                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-gold-600 hover:text-white hover:bg-gold-600"
                      >
                        <GitHub className="h-4 w-4"></GitHub>
                      </Link>
                    </li>
                    <li className="inline ms-1">
                      <Link
                        to=""
                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-gold-600 hover:text-white hover:bg-gold-600"
                      >
                        <Youtube className="h-4 w-4"></Youtube>
                      </Link>
                    </li>
                    <li className="inline ms-1">
                      <Link
                        to=""
                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-gold-600 hover:text-white hover:bg-gold-600"
                      >
                        <Gitlab className="h-4 w-4"></Gitlab>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <RelatedBlogs />
          <div className="container lg:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
              <h3 className="mb-6 md:text-2xl text-xl font-medium">
                Have Question ? Get in touch!
              </h3>

              <p className="text-slate-400 max-w-xl mx-auto">
                This is just a simple text made for this unique and awesome
                template, you can replace it with any text.
              </p>

              <div className="mt-6">
                <Link
                  to="/contactus"
                  className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md"
                >
                  <i className="uil uil-phone align-middle me-2"></i> Contact us
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    )
  );
}
