import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "./config";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  const [registerId, setRegisterId] = useState(null);
  const [toastrMsg, setToastrMsg] = useState(null);
  const [toastrError, setToastrError] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [filled, setFilled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [gRegistered, setGRegistered] = useState(null);
  const [isRegistered, setIsRegistered] = useState(null);
  const [myDetails, setMyDetails] = useState(null);

  const register = (email, name, phone, gSign = false) => {
    axios
      .post(`${BASE_URL}/api/register/`, { email, name, phone, gSign })
      .then((res) => {
        let incomingData = res.data;
        // console.log(incomingData);
        if (incomingData.success) {
          setToastrMsg(incomingData.msg);
          if (gSign) {
            localStorage.setItem("userInfo", JSON.stringify(incomingData));
            setGRegistered(true);
          } else {
            setIsRegistered(true);
          }
          setToastrError(null);
        } else if (!incomingData.success) {
          setToastrError(incomingData.msg);
          setToastrMsg(null);
        }
      })
      .catch((err) => {
        setToastrError("An error occured. Please try again");
        setToastrMsg(null);
      });
  };

  /*
  const register = (
    firstName,
    lastName,
    email,
    phone,
    password1,
    password2
  ) => {
    // console.log(phone, firstName, lastName);
    axios
      .post(`${BASE_URL}/api/register/`, {
        firstName,
        lastName,
        email,
        phone,
        password1,
        password2,
      })
      .then((res) => {
        let registerInfo = res.data;
        // console.log(res.data);
        if (registerInfo.success) {
          setRegisterId(registerInfo.userId);
          localStorage.setItem("userId", registerInfo.userId);
          setToastrError(null);
          setToastrMsg(registerInfo.msg);
        } else if (!registerInfo.success) {
          setToastrMsg(null);
          setToastrError(registerInfo.msg);
        }
      })
      .catch((err) => {
        setToastrMsg(null);
        setToastrError("An error occured. Please try again");
      });
    // setIsLoggedIn(true);
  };
  */

  const verifyOtp = (userId, otp) => {
    axios
      .post(`${BASE_URL}/api/verify-otp/`, {
        userId,
        otp,
      })
      .then((res) => {
        let incomingInfo = res.data;
        // console.log(res.data);
        if (incomingInfo.success) {
          setUserInfo(incomingInfo);
          // console.log(userInfo);
          localStorage.setItem("cluserInfo", JSON.stringify(incomingInfo));
          setToastrError(null);
          setToastrMsg(incomingInfo.msg);
        } else if (!incomingInfo.success) {
          setToastrMsg(null);
          setToastrError(incomingInfo.msg);
        }
      })
      .catch((err) => {
        setToastrMsg(null);
        setToastrError("An error occured. Please try again");
      });
  };

  const resendOtp = (userId) => {
    axios
      .post(`${BASE_URL}/api/resend-otp/`, {
        userId,
      })
      .then((res) => {
        const incomingInfo = res.data;
        if (incomingInfo.success) {
          setToastrError(null);
          setToastrMsg(incomingInfo.msg);
        } else if (!incomingInfo.success) {
          setToastrMsg(null);
          setToastrError(incomingInfo.msg);
        }
      })
      .catch((err) => {
        setToastrMsg(null);
        setToastrError("An error occured. Please try again");
      });
  };

  const resetOtp = (email) => {
    axios
      .post(`${BASE_URL}/api/reset-otp/`, {
        email,
      })
      .then((res) => {
        let incomingInfo = res.data;
        // console.log(incomingInfo.msg);
        if (incomingInfo.success) {
          setToastrError(null);
          setToastrMsg(incomingInfo.msg);
        } else if (!incomingInfo.success) {
          setToastrMsg(null);
          setToastrError(incomingInfo.msg);
        }
      })
      .catch((err) => {
        setToastrMsg(null);
        setToastrError("An error occured. Please try again");
      });
  };

  const verifyResetOtp = (email, otp) => {
    const userId = localStorage.getItem("resetUser");
    axios
      .post(`${BASE_URL}/api/verify-reset-otp/`, {
        email,
        otp,
      })
      .then((res) => {
        let incomingInfo = res.data;
        // console.log(incomingInfo);
        if (incomingInfo.success) {
          setToastrError(null);
          setToastrMsg(incomingInfo.msg);
        } else if (!incomingInfo.success) {
          setToastrMsg(null);
          setToastrError(incomingInfo.msg);
        }
      })
      .catch((err) => {
        setToastrMsg(null);
        setToastrError("An error occured. Please try again");
      });
  };

  const resetPassword = (email, password) => {
    const userId = localStorage.getItem("resetUser");
    axios
      .post(`${BASE_URL}/api/reset-password/`, {
        email,
        password,
      })
      .then((res) => {
        let incomingInfo = res.data;
        if (incomingInfo.success) {
          setToastrError(null);
          setToastrMsg(incomingInfo.msg);
        } else if (!incomingInfo.success) {
          setToastrMsg(null);
          setToastrError(incomingInfo.msg);
        }
      })
      .catch((err) => {
        setToastrMsg(null);
        setToastrError("An error occured. Please try again");
      });
  };

  const loginUser = (username, password, choice) => {
    axios
      .post(`${BASE_URL}/api/login-user/`, {
        username,
        password,
        choice,
      })
      .then((res) => {
        let incomingInfo = res.data;
        if (incomingInfo.success) {
          setUserInfo(incomingInfo);
          setToastrError(null);
          setToastrMsg(incomingInfo.msg);
          localStorage.setItem("cluserInfo", JSON.stringify(incomingInfo));
        } else if (!incomingInfo.success) {
          setToastrMsg(null);
          setToastrError(incomingInfo.msg);
        }
      })
      .catch((err) => {
        setToastrMsg(null);
        setToastrError("An error occured. Please try again");
      });
  };

  const projects = () => {
    axios
      .get(`${BASE_URL}/api/projects/`)
      .then((res) => {
        let incomingInfo = res.data;
        if (incomingInfo.success) {
          // console.log(incomingInfo.data);
          setProjectData(incomingInfo.data);
        }
      })
      .catch((err) => {});
  };

  const getProjectDetails = (id) => {
    axios
      .get(`${BASE_URL}/api/project-details/${id}/`)
      .then((res) => {
        let incomingInfo = res.data;
        if (incomingInfo.success) {
          // console.log(incomingInfo.data);
          setProjectDetails(incomingInfo.data);
        }
      })
      .catch((err) => {});
  };

  const getUserDetails = () => {
    axios
      .get(
        `${BASE_URL}/api/user-details/`,

        {
          headers: {
            Authorization: `Token ${
              JSON.parse(localStorage.getItem("cluserInfo"))?.token
            }`,
          },
        }
      )
      .then((res) => {
        let incomingData = res.data;
        if (incomingData.success) {
          setMyDetails(incomingData.data);
        }
      })
      .catch((err) => {});
  };

  const updateUserDetails = (formData) => {
    // console.log(formData);
    axios
      .post(`${BASE_URL}/api/update-user-details/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${
            JSON.parse(localStorage.getItem("cluserInfo"))?.token
          }`,
        },
      })
      .then((res) => {
        let incomingData = res.data;
        if (incomingData.success) {
          getUserDetails();
          setToastrError(null);
          setToastrMsg(incomingData.msg);
          let userInfoString = localStorage.getItem("cluserInfo");
          let userInfo = JSON.parse(userInfoString);
          userInfo.name = incomingData.name;
          let updatedUserInfoString = JSON.stringify(userInfo);
          localStorage.setItem("cluserInfo", updatedUserInfoString);
        }
        else {
          setToastrMsg(null);
          setToastrError(incomingData.msg);
        }
      })
      .catch((err) => {
        setToastrMsg(null);
        setToastrError("An error occured. Please try again later.");
      });
  };

  const changePassword = (formData) => {
    // console.log(formData);
    axios
      .post(`${BASE_URL}/api/change-password/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${
            JSON.parse(localStorage.getItem("cluserInfo"))?.token
          }`,
        },
      })
      .then((res) => {
        let incomingData = res.data;
        if (incomingData.success) {
          setToastrError(null);
          setToastrMsg(incomingData.msg);
        }
        else {
          setToastrMsg(null);
          setToastrError(incomingData.msg);
        }
      })
      .catch((err) => {
        setToastrMsg(null);
        setToastrError("An error occured. Please try again later.");
      });
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("userId");
    localStorage.removeItem("cluserInfo");
  };

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        registerId,
        filled,
        registerId,
        toastrMsg,
        toastrError,
        projectData,
        projectDetails,
        myDetails,
        changePassword,
        updateUserDetails,
        getUserDetails,
        getProjectDetails,
        projects,
        setToastrMsg,
        setToastrError,
        resetPassword,
        verifyResetOtp,
        resetOtp,
        loginUser,
        resendOtp,
        setFilled,
        register,
        logout,
        verifyOtp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
