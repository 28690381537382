import { useContext } from "react";
import { AuthContext } from "../utils/Authcontext";
import { Navigate, Outlet } from "react-router-dom";
// import { useLocation } from "react-router";

export const PrivateRegisterRoute = ({ children }) => {
  return JSON.parse(localStorage.getItem("cluserInfo"))?.token ? (
    <>{children}</>
  ) : (
    <Navigate to="/signup" />
  );
};

export const AuthenticatedRoute = ({ children }) => {
  return !JSON.parse(localStorage.getItem("cluserInfo"))?.token ? (
    <>{children}</>
  ) : (
    <Navigate to="/" />
  );
};
