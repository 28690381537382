import logo_light from "../assets/images/logo-light.png";
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/Authcontext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ResetPassword() {
  const navigate = useNavigate();
  const {
    resetOtp,
    verifyResetOtp,
    resetPassword,
    toastrMsg,
    toastrError,
    setToastrMsg,
    setToastrError,
  } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [state, setState] = useState("1");

  useEffect(() => {
    if (toastrMsg) {
      (state == "1" && setState("2")) ||
        (state == "2" && setState("3")) ||
        (state == "3" && navigate("/login"));
      setToastrError(null);
      toast.success(toastrMsg);

      setToastrMsg(null);
    } else if (toastrError) {
      setToastrMsg(null);
      toast.error(toastrError);
      setToastrError(null);
    }
  }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

  const onSendEmail = (email) => {
    resetOtp(email);
    // navigate("/reset-password");
  };

  const onSendOtp = (otp) => {
    verifyResetOtp(email, otp);
    // navigate("/reset-password");
  };

  const onSendConfirmation = (password1, password2) => {
    if (password1 !== "" && password2 !== "") {
      if (password1 !== password2) {
        toast.error("Passowrds do not match");
        return;
      }
      resetPassword(email, password1);
      // navigate("/login");
    }
  };
  return (
    <div className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
      <ToastContainer />
      {(state == "2" && (
        <section className="position-relative bg-[url('../../assets/images/bg/bg-4.jpg')] bg-center bg-no-repeat">
          <div className="absolute inset-0 bg-slate-950/50"></div>
          <div className="container-fluid relative">
            <div className="grid grid-cols-1">
              <div className="lg:col-span-4">
                <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                  <div className="text-center mx-auto">
                    <Link to="/index">
                      <img src={logo_light} alt="" />
                    </Link>
                  </div>
                  <div className="text-center my-auto">
                    <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                      <div className="grid grid-cols-1">
                        <h5 className="mb-8 text-xl dark:text-white font-medium">
                          Reset Your Password
                        </h5>
                        <p className="text-slate-400 mb-6">
                          Please enter the OTP we've sent to your registered
                          Email
                        </p>
                        <form className="text-start">
                          <div className="grid grid-cols-1">
                            <div className="mb-4">
                              <label
                                className="form-label font-medium"
                                for="resetOTP"
                              >
                                OTP:
                              </label>
                              <input
                                id="resetOTP"
                                type="text"
                                value={otp}
                                onChange={(event) => {
                                  setOtp(event.target.value);
                                }}
                                className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                              />
                            </div>
                            {/* <div className="text-center">
                              <Link
                                onClick={() => {
                                  handleResendOtp();
                                }}
                                to=""
                                className="text-slate-950 dark:text-white font-bold"
                              >
                                Resend OTP
                              </Link>
                            </div> */}

                            <div className="mb-4">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  onSendOtp(otp);
                                }}
                                className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md w-full"
                              >
                                Send
                              </button>
                            </div>
                            <div className="text-center">
                              <Link
                                onClick={() => {}}
                                to=""
                                className="text-slate-950 dark:text-white font-bold"
                              >
                                Resend OTP
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )) ||
        (state == "3" && (
          <section className="position-relative bg-[url('../../assets/images/bg/bg-4.jpg')] bg-center bg-no-repeat">
            <div className="absolute inset-0 bg-slate-950/50"></div>
            <div className="container-fluid relative">
              <div className="grid grid-cols-1">
                <div className="lg:col-span-4">
                  <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                    <div className="text-center mx-auto">
                      <Link to="/index">
                        <img src={logo_light} alt="" />
                      </Link>
                    </div>

                    <div className="text-center my-auto">
                      <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                        <div className="grid grid-cols-1">
                          <h5 className="mb-8 text-xl dark:text-white font-medium">
                            Reset Your Password
                          </h5>
                          <form className="text-start">
                            <div className="grid grid-cols-1">
                              <div className="mb-4">
                                <label
                                  className="form-label font-medium"
                                  htmlFor="password"
                                >
                                  Password:<span className="required"></span>
                                </label>

                                <input
                                  id="password"
                                  type="password"
                                  value={password1}
                                  onChange={(event) => {
                                    setPassword1(event.target.value);
                                  }}
                                  className="form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                                  placeholder="Password:"
                                />
                              </div>

                              <div className="mb-4">
                                <label
                                  className="form-label font-medium"
                                  htmlFor="repassword"
                                >
                                  Retype Password:
                                </label>
                                <input
                                  id="repassword"
                                  type="password"
                                  value={password2}
                                  onChange={(event) => {
                                    setPassword2(event.target.value);
                                  }}
                                  className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                                  placeholder="Retype Password:"
                                />
                              </div>

                              <div className="mb-4">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onSendConfirmation(password1, password2);
                                  }}
                                  className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md w-full"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )) ||
        (state == "1" && (
          <section className="position-relative bg-[url('../../assets/images/bg/bg-4.jpg')] bg-center bg-no-repeat">
            <div className="absolute inset-0 bg-slate-950/50"></div>
            <div className="container-fluid relative">
              <div className="grid grid-cols-1">
                <div className="lg:col-span-4">
                  <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                    <div className="text-center mx-auto">
                      <Link to="/index">
                        <img src={logo_light} alt="" />
                      </Link>
                    </div>
                    <div className="text-center my-auto">
                      <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                        <div className="grid grid-cols-1">
                          <h5 className="mb-8 text-xl dark:text-white font-medium">
                            Reset Your Password
                          </h5>
                          <p className="text-slate-400 mb-6">
                            Please enter your registered Email.
                          </p>
                          <form className="text-start">
                            <div className="grid grid-cols-1">
                              <div className="mb-4">
                                <label
                                  className="form-label font-medium"
                                  htmlFor="email"
                                >
                                  Email Address:
                                </label>
                                <input
                                  id="email"
                                  type="email"
                                  className="required form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                                  placeholder="Email"
                                  value={email}
                                  onChange={(event) => {
                                    setEmail(event.target.value);
                                  }}
                                />
                              </div>

                              <div className="mb-4">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onSendEmail(email);
                                  }}
                                  className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md w-full"
                                >
                                  Send
                                </button>
                              </div>

                              <div className="text-center">
                                <span className="text-slate-400 me-2">
                                  Remember your password ?{" "}
                                </span>
                                <Link
                                  to="/login"
                                  className="text-slate-950 dark:text-white font-bold"
                                >
                                  {" "}
                                  Sign in
                                </Link>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}

      <div className="fixed bottom-3 end-3">
        <Link
          to=""
          className="back-button h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white"
        >
          <ArrowLeft className="h-4 w-4"></ArrowLeft>
        </Link>
      </div>
    </div>
  );
}
