import contact from "../assets/images/contact.svg";
import React from "react";
import Navbar from "../component/navbar";
import ContactData from "../component/contact-data";
import Footer from "../component/footer";

export default function ContactUs() {
  return (
    <>
      <Navbar />
      <section
        className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"
        id="home"
      >
        <div className="absolute inset-0 bg-slate-950/80"></div>

        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">
              Contact Us
            </h3>
          </div>
        </div>
      </section>
      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7 md:col-span-6">
              <img src={contact} alt="" />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-medium">
                    Get in touch !
                  </h3>

                  <form
                    method="post"
                    name="myForm"
                    id="myForm"
                    onSubmit="return validateForm()"
                  >
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <label
                          htmlFor="name"
                          className="form-label font-medium"
                        >
                          Your Name:
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                          placeholder="Name :"
                        />
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <label
                          htmlFor="email"
                          className="form-label font-medium"
                        >
                          Your Email:
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                          placeholder="Email :"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <label
                          htmlFor="subject"
                          className="form-label font-medium"
                        >
                          Your Question:
                        </label>
                        <input
                          name="subject"
                          id="subject"
                          className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                          placeholder="Subject :"
                        />
                      </div>

                      <div className="mb-5">
                        <label
                          htmlFor="comments"
                          className="form-label font-medium"
                        >
                          Your Comment:
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          className="form-input w-full py-2 px-3 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2 textarea h-28"
                          placeholder="Message :"
                        ></textarea>
                      </div>
                    </div>
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md"
                    >
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactData />
      </section>
      <div className="container-fluid relative">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            {/* <iframe
              // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d0.0!2d77.6354228!3d12.9059977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2z12.9059977!3m2!1d12.9059977!2d77.6354228!5e0!3m2!1sen!2sin!4v1643545456755!5m2!1sen!2sin
              "
              style={{ border: 0 }}
              className="w-full h-[500px]"
              title="contactUs"
              allowfullscreen
            >
              {" "}
            </iframe> */}
            <iframe
              style={{ border: 0 }}
              className="w-full h-[500px]"
              title="contactUs"
              allowfullscreen
              src="https://www.google.com/maps/embed?center=12.9059977,77.6354228&zoom=15
              "
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
