import logo_dark from "../assets/images/logo-dark.png";
import logo_light from "../assets/images/logo-light.png";
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/Authcontext";
import { useMediaQuery } from "react-responsive";

export default function Navbar(props) {
  const [isOpen, setMenu] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  window.addEventListener("scroll", windowScroll);
  useEffect(() => {}, []);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const toggleMenu = () => {
    setMenu(!isOpen);
  };

  const handleLogout = () => {
    setMenu(!isOpen);
    localStorage.removeItem("cluserInfo");
    localStorage.removeItem("userId");
    navigate("/");
  };

  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add("is-sticky");
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove("is-sticky");
      }
    }

    const mybutton = document.getElementById("back-to-top");
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.classList.add("flex");
        mybutton.classList.remove("hidden");
      } else {
        mybutton.classList.add("hidden");
        mybutton.classList.remove("flex");
      }
    }
  }
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <>
      <nav className="navbar" id="navbar">
        <div className="container flex flex-wrap items-center justify-end">
          <a className="navbar-brand md:me-8" href="/index">
            <span className="inline-block dark:hidden">
              <img src={logo_dark} className="l-dark" alt="" />
              <img src={logo_light} className="l-light" alt="" />
            </span>
            <img src={logo_light} className="hidden dark:inline-block" alt="" />
          </a>

          <div className="nav-icons flex items-center lg_992:order-2 ms-auto lg:ms-4">
            <ul className="list-none menu-social mb-0">
              {(localStorage.getItem("cluserInfo") && isDesktop && (
                <>
                  <li className="inline">
                    <button
                      type="button"
                      className="inline-flex h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white"
                      onClick={() => handleDropdown()}
                    >
                      <img
                        className="h-24 w-24 mx-auto"
                        alt="..."
                        src={`https://ui-avatars.com/api/?name=${
                          JSON.parse(localStorage.getItem("cluserInfo"))?.name
                        }&background=A88B5C&bold=true&rounded=true`}
                      />
                    </button>
                    {/* <UncontrolledDropdown nav>
                      <DropdownToggle className="pr-0" nav></DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem className="noti-title" header tag="div">
                          <h6 className="text-overflow m-0">Welcome!</h6>
                        </DropdownItem>
                        <DropdownItem to="/admin/user-profile" tag={Link}>
                          <i className="ni ni-single-02" />
                          <span>My profile</span>
                        </DropdownItem>
                        <DropdownItem to="/admin/user-profile" tag={Link}>
                          <i className="ni ni-settings-gear-65" />
                          <span>Settings</span>
                        </DropdownItem>

                        <DropdownItem divider />
                        <DropdownItem onClick={() => handleLogout()}>
                          <i className="ni ni-user-run" />
                          <span>Logout</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                  </li>
                  <div
                    className={`${
                      dropdown
                        ? "absolute mt-2  bg-white rounded-lg shadow-lg left-0 block"
                        : "absolute mt-2  bg-white rounded-lg shadow-lg left-0 hidden"
                    }`}
                  >
                    <ul className="p-2">
                      <li className="cursor-pointer px-2 py-2 text-sm">
                        <Link to="/profile">View Profile</Link>
                      </li>
                      <li
                        className="cursor-pointer px-2 py-2 text-sm"
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </li>
                    </ul>
                  </div>
                </>
              )) ||
                (!localStorage.getItem("cluserInfo") && (
                  <li className="inline">
                    <Link
                      to={localStorage.getItem("cluserInfo") ? "/" : "/login"}
                      className="h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white"
                      // onClick={handleClick()}
                    >
                      <i className="uil uil-user"></i>
                    </Link>
                  </li>
                ))}
              {/* <li className="inline">
                <Link
                  to={
                    !JSON.parse(localStorage.getItem("cluserInfo"))?.token
                      ? "/login"
                      : ""
                  }
                  className="h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white"
                >
                  <i className="uil uil-user"></i>
                </Link>
              </li> */}
            </ul>

            <button
              data-collapse="menu-collapse"
              type="button"
              onClick={toggleMenu}
              className=" btn collapse-btn inline-flex items-center ms-3 text-slate-950 dark:text-white lg_992:hidden"
              aria-controls="menu-collapse"
              aria-expanded="false"
            >
              <span className="sr-only">Navigation Menu</span>
              <i className="mdi mdi-menu text-[24px]"></i>
            </button>
          </div>
          <div
            className={`${
              isOpen === true
                ? "navigation lg_992:order-1 lg_992:flex hidden ms-auto"
                : "navigation lg_992:order-1 lg_992:flex block ms-auto"
            }`}
            id="menu-collapse"
          >
            <ul
              className="navbar-nav nav-light sidebar-nav"
              id="navbar-navlist"
            >
              <li
                className={`nav-item navbar-item ${
                  splitLocation[1] === "" ? "active" : ""
                }`}
              >
                <Link to="/index" className="nav-link ">
                  Home
                </Link>
              </li>
              <li
                className={`nav-item navbar-item ${
                  splitLocation[1] === "aboutus" ? "active" : ""
                }`}
              >
                <Link to="/aboutus" className="nav-link">
                  About Us
                </Link>
              </li>
              {/* <li
                className={`nav-item navbar-item ${
                  splitLocation[1] === "services" ? "active" : ""
                }`}
              >
                <Link to="/services" className="nav-link">
                  Services
                </Link>
              </li>
              <li
                className={`nav-item navbar-item ${
                  splitLocation[1] === "pricing" ? "active" : ""
                }`}
              >
                <Link to="/pricing" className="nav-link">
                  Pricing
                </Link>
              </li> */}
              {/* <li
                className={`nav-item navbar-item ${
                  splitLocation[1] === "team" ? "active" : ""
                }`}
              >
                <Link to="/team" className="nav-link">
                  Team
                </Link>
              </li> */}
              <li
                className={`nav-item navbar-item ${
                  splitLocation[1] === "blogs" ? "active" : ""
                }`}
              >
                <Link to="/blogs" className="nav-link">
                  Blogs
                </Link>
              </li>
              <li
                className={`nav-item navbar-item ${
                  splitLocation[1] === "contactus" ? "active" : ""
                }`}
              >
                <Link to="/contactus" className="nav-link">
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
