import logo_light from "../assets/images/logo-light.png";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { AuthContext } from "../utils/Authcontext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [choice, setChoice] = useState("email");

  const {
    loginUser,
    userInfo,
    setUserInfo,
    toastrMsg,
    toastrError,
    setToastrMsg,
    setToastrError,
  } = useContext(AuthContext);

  const handleLogin = (phone, password) => {
    // console.log(username, password);
    if (username !== null && password !== null) {
      loginUser(username, password, choice);
    }
  };

  useEffect(() => {
    if (toastrMsg) {
      setToastrError(null);
      toast.success(toastrMsg);
      setToastrMsg(null);
    } else if (toastrError) {
      setToastrMsg(null);
      toast.error(toastrError);
      setToastrError(null);
    }
  }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

  useEffect(() => {
    // console.log(userInfo);
    if (JSON.parse(localStorage.getItem("cluserInfo"))?.token) {
      navigate("/");
    } else {
    }
  }, [userInfo]);
  return (
    <>
      <ToastContainer />
      <div className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
        <section className="position-relative bg-[url('../../assets/images/bg/bg-4.jpg')] bg-center bg-no-repeat">
          <div className="absolute inset-0 bg-slate-950/50"></div>
          <div className="container-fluid relative">
            <div className="grid grid-cols-1">
              <div className="lg:col-span-4">
                <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                  <div className="text-center mx-auto">
                    <Link to="/index">
                      <img src={logo_light} alt="" />
                    </Link>
                  </div>

                  <div className="text-center my-auto">
                    <div className="w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-gray-800">
                      <div className="grid grid-cols-1">
                        <h5 className="mb-8 text-xl dark:text-white font-medium">
                          Login
                        </h5>
                        <form className="text-start">
                          <div className="grid grid-cols-1">
                            <div className="flex justify-center mb-4">
                              <div className="flex items-center mb-0 me-2">
                                <input
                                  className="form-radio rounded border-gray-200 dark:border-gray-800 text-gold-600 focus:border-gold-300 focus:ring focus:ring-offset-0 focus:ring-gold-200 focus:ring-opacity-50 me-2"
                                  type="radio"
                                  value="email"
                                  id="email-box"
                                  name="contact-method"
                                  checked={choice === "email" ? true : false}
                                  onClick={() => {
                                    setUsername("");
                                    setChoice("email");
                                  }}
                                />
                                <label
                                  className="form-checkbox-label text-slate-400"
                                  htmlFor="email-box"
                                >
                                  Email
                                </label>
                              </div>
                              <div className="flex items-center mb-0">
                                <input
                                  className="form-radio rounded border-gray-200 dark:border-gray-800 text-gold-600 focus:border-gold-300 focus:ring focus:ring-offset-0 focus:ring-gold-200 focus:ring-opacity-50 me-2"
                                  type="radio"
                                  value="phone"
                                  id="phone-box"
                                  name="contact-method"
                                  checked={choice === "phone" ? true : false}
                                  onClick={() => {
                                    setUsername("");
                                    setChoice("phone");
                                  }}
                                />
                                <label
                                  className="form-checkbox-label text-slate-400"
                                  htmlFor="phone-box"
                                >
                                  Phone
                                </label>
                              </div>
                            </div>

                            {(choice === "email" && (
                              <div className="mb-4">
                                <label
                                  className="form-label font-medium"
                                  htmlFor="email"
                                >
                                  Email Address:
                                </label>
                                <input
                                  id="email"
                                  type="email"
                                  className="required form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                                  placeholder="Email"
                                  value={username}
                                  onChange={(event) => {
                                    setUsername(event.target.value);
                                  }}
                                />
                              </div>
                            )) ||
                              (choice === "phone" && (
                                <div className="mb-4">
                                  <label
                                    className="form-label font-medium"
                                    htmlFor="phone"
                                  >
                                    Phone:<span className="required"></span>
                                  </label>

                                  <PhoneInput
                                    id="phoneLogin"
                                    placeholder="Phone Number"
                                    value={username}
                                    onChange={(event) => {
                                      setUsername(event);
                                    }}
                                    className="form-input w-full py-1 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                                  />
                                </div>
                              ))}

                            <div className="mb-4">
                              <label
                                className="form-label font-medium"
                                htmlFor="LoginPassword"
                              >
                                Password:
                              </label>
                              <input
                                id="LoginPassword"
                                type="password"
                                className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-gold-600/50 dark:focus:border-gold-600/50 focus:ring-0 mt-2"
                                placeholder="Password:"
                                value={password}
                                onChange={(event) => {
                                  setPassword(event.target.value);
                                }}
                              />
                            </div>

                            <div className="flex justify-between mb-4">
                              <div className="flex items-center mb-0">
                                <input
                                  className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-gold-600 focus:border-gold-300 focus:ring focus:ring-offset-0 focus:ring-gold-200 focus:ring-opacity-50 me-2"
                                  type="checkbox"
                                  value=""
                                  id="RememberMe"
                                />
                                <label
                                  className="form-checkbox-label text-slate-400"
                                  htmlFor="RememberMe"
                                >
                                  Remember me
                                </label>
                              </div>
                              <p className="text-slate-400 mb-0">
                                <Link
                                  to="/reset-password"
                                  className="text-slate-400"
                                >
                                  Forgot password ?
                                </Link>
                              </p>
                            </div>

                            <div className="mb-4">
                              <Link
                                to=""
                                className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white rounded-md w-full"
                                onClick={() => {
                                  handleLogin(phone, password);
                                }}
                              >
                                Login
                              </Link>
                            </div>

                            <div className="text-center">
                              <span className="text-slate-400 me-2">
                                Don't have an account ?
                              </span>{" "}
                              <Link
                                to="/signup"
                                className="text-slate-950 dark:text-white font-bold"
                              >
                                Sign Up
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="fixed bottom-3 end-3">
          <Link
            to=""
            className="back-button h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-gold-600 hover:bg-gold-700 border-gold-600 hover:border-gold-700 text-white"
          >
            <ArrowLeft className="h-4 w-4"></ArrowLeft>
          </Link>
        </div>
      </div>
    </>
  );
}
