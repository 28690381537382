import "./App.css";
import React, { useEffect } from "react";
import URlRoute from "./routes/urlroutes";
import { AuthProvider } from "./utils/Authcontext";

export default function App() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
  }, []);

  return (
    <AuthProvider>
      <URlRoute />
    </AuthProvider>

    // <BrowserRouter>
    //   <ScrollToTop />
    //   <Routes>
    //     <Route path="/aboutus" element={<AboutUs />} />
    //     <Route path="/blog-detail" element={<BlogDetail />} />
    //     <Route path="/blogs" element={<Blogs />} />
    //     <Route path="/contactus" element={<ContactUs />} />
    //     <Route path="/index-four" element={<IndexFour />} />
    //     <Route path="/index-three" element={<IndexThree />} />
    //     <Route path="/index-two" element={<IndexTwo />} />
    //     <Route path="/index" element={<Index />} />
    //     <Route path="/login" element={<Login />} />
    //     <Route path="/otp-verify" element={<OtpVerify />} />
    //     <Route path="/pricing" element={<Pricing />} />
    //     <Route path="/reset-password" element={<ResetPassword />} />
    //     <Route path="/services" element={<Services />} />
    //     <Route path="/signup" element={<Signup />} />
    //     <Route path="/team" element={<Team />} />
    //     <Route path="/" element={<Index />} />
    //   </Routes>
    //   <Switcher />
    // </BrowserRouter>
  );
}
